import React from 'react'
import aboutimage from '../images/about.png'

export const About = () => {
  return (
    <div className='about'>
        <div className="about-model">
            <img src={aboutimage} alt="about image" />
        </div>

        <div className="about-text">
            <h2> دەربارەی ئەپلیکەیشن</h2>
            <p>ئەپلیکەیشنی بەرەوزانکۆ یەکەمی ئەپلیکەیشنە لە هەرێمی کوردستان کە بۆ ڕێنمایی و هاوکاری خوێندکارانە بۆ وەرگرتنیان لە زانکۆ و پەیمانگاکان 
              ئەپلیکەیشنەکە پێک هاتووە لە کەمترین کۆنمرەی وەرگیراوی زانکۆ و پەیمانگاکان ، زانیاری تەواو لەسەر بەشەکان و داهات و کار کردنیان ، کرێی خوێندنی پارالێل و ئێواران ، زانیاری لەسەر زانکۆ و پەیمانگا ئەهلیەکان بەشەکان و کرێی خوێندنیان ، زانیاری لەسەر سیستمەکانی خوێندن ...........
            </p>
        </div>
    </div>
  )
}
