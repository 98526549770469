import React from 'react'
import { Navbar } from './Navbar'
import barawzanko from '../images/barawzanko.jpg'
import playstore from '../images/play store.png'
import appstore from '../images/app store.png'


export const Header = () => {
  return (
    <div className='header'>
      <Navbar />
      <div className='intro'>
        <div className='img-home'>   <img src={barawzanko} alt="" /></div>
      </div>

      <div className="intro-right">
        <p>پلاتفۆڕمی بەرەوزانکۆ</p>
        <h1> <span>بۆ هاوکاری </span>و <span>ڕێنمایی </span>خوێندکاران و قوتابیان </h1>
        <p className='details'>یەکەمین ئەپلیکەیشنی تایبەت بە ڕێنمایی و هاوکاری خوێندکاران و قوتابیانی دەرچووی پۆلی ١٢</p>


        <div className="link-app">
          <a href="https://play.google.com/store/apps/details?id=com.rasty.barawzanko&hl=en_US" target="_blank">  
          <img src={playstore} alt="" /> </a>
          
          <a href="https://apps.apple.com/iq/app/barawzanko/id1671830962" target="_blank">
            <img src={appstore} alt="" /></a>

        </div>
      </div>

    </div>
  )
}
