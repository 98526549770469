import React from 'react'

export const Howitwork = () => {
  return (
    <div className="how-it-works">
        <div className="container">
            <h2>ئەزموونی ئێمە</h2>

 

            <div className="flex">
                <div>
                <span class="Circle">5+</span>
                <h4>ئەزموون</h4>
                    <p> زیات لە 5 ساڵ ئەزمونمان هەیە لە پڕکردنەوەی فۆڕمی زانکۆلاین</p>
                </div>
                <div>
                <span class="Circle">50,000+</span>
                <h4>بەکارهێنەر</h4>
                    <p>زیاتر لە 50,000 خوێندکار و قوتابی سودمەند بوون لە بەکارهێنانی ئەپلیکەیشنی بەرەوزانکۆ</p>
                </div>
                <div>
                <span class="Circle">24</span>
                <h4>٣٤ کاژێر بەردەست</h4>
                    <p>بەردوام لە خزمەتی خوێندکاران قوتابیانین بۆ هاوکاری کردن و وەڵمدانەوەی پرسیارەکانیان</p>
                </div>
            </div>
        </div>
    </div>
  )
}
