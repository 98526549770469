import { Header } from './Component/Header';
import { Howitwork } from './Component/Howitwork';
import { About } from './Component/About';
import Contact from './Component/Contact/Contact';
  
function App() {
  return (
 
     <div className="App">
    <Header/>
     <Howitwork/>
      <About/>
      <Contact/>
    </div>
 

  );
}

export default App;
