import logo from '../images/logo.png'
import { Link } from 'react-scroll';
import React, { useEffect, useState } from 'react'
import { FiAlignJustify } from "react-icons/fi";
// import './Navbar.css'

export const Navbar = () => {

  
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.scrollY > 500 ? setSticky(true) : setSticky(false)
    })
  }, []);

  const [mobileMenu, setMobileMenu] = useState(false);
  const toggleMenu = () => {

    mobileMenu ? setMobileMenu(false) : setMobileMenu(true);

  }


  return (
    <nav className={`container ${sticky ? 'dark-nav' : ''}`}>

        <a href="#" className='logo'>
            <img src={logo} alt="logo" />
        </a>

        <input type="checkbox" className='menu-btn' id='menu-btn' />

        <label className='menu-icon' for='menu-btn'>
            <span className='nav-icon'></span>
        </label>

        <ul className={mobileMenu? '':'menu'}>
    
        <li><Link to='contact' smooth={true} offset={-260} duration={500}  >پەیوەنی</Link></li>
        <li><Link to='about' smooth={true} offset={-150} duration={500}>دەربارەی </Link></li>

        <li><Link to='how-it-works' smooth={true} offset={-260} duration={500}>ئەزمون</Link></li>

        <li><Link to='header' smooth={true} offset={0} duration={500} >سەرەکی</Link> </li>

            {/* <li><a href="#" className='active'>Home</a></li>
            <li><a href="#">About</a></li>
            <li><a href="#">Agents</a></li>
            <li><a href="#">Property</a></li>
            <li><a href="#">Contact</a></li> */}
        </ul>
        {/* <a href="#" className='property'>Properties</a> */}
        <FiAlignJustify className='menu-icon' onClick={toggleMenu} />

    </nav>
  )
}
